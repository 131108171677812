import React from "react";
import { Button, Spinner } from "reactstrap";

export default function ButtonSpinner(props) {
  const { isLoading, title, onClick, isDisabled = false, size = 80 } = props;
  return (
    <Button
      color={isLoading ? "secondary" : "success"}
      className="waves-effect waves-light"
      style={{ width: isLoading ? "auto" : size }}
      disabled={isLoading || isDisabled}
      onClick={onClick}
    >
      {isLoading ? <Spinner size="sm" /> : null}
      <span> {`  ${title}`}</span>
    </Button>
  );
}
