/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";

//redux
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";

import { useMutation } from "react-query";

//services
import { updateFlight } from "../../../utils/services/flight";

import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function Editlight(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //state de participantes
  const participants = props.participants;
  const [participantSelected, setParticipantSelected] = useState();
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state dos campos obrigatórios
  const [participantError, setParticipantError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [sourcerError, setSourceError] = useState("");
  const [destinyError, setDestinyError] = useState("");
  const [dateBoardingError, setDateBoardingError] = useState("");
  const [timeBoardingError, setTimeBoardingError] = useState("");
  const [dateArrivalError, setDateArrivalError] = useState("");
  const [timeArrivalError, setTimeArrivalError] = useState("");
  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function handleParticipant(a) {
    setParticipantSelected(a);
  }

  const submitForm = useMutation(
    async (e) => {
      //tratamento de erros
      if (!participantSelected || participantSelected === "Selecione") {
        setParticipantError("O campo Participante é obrigatório");
        return;
      } else setParticipantError("");

      if (e.target[2].value === "") {
        setDateBoardingError("O campo Data embarque é obrigatório");
        return;
      } else setDateBoardingError("");

      if (e.target[3].value === "") {
        setTimeBoardingError("O campo Hora embarque é obrigatório");
        return;
      } else setTimeBoardingError("");

      if (e.target[4].value === "") {
        setDateArrivalError("O campo Data chegada é obrigatório");
        return;
      } else setDateArrivalError("");

      if (e.target[5].value === "") {
        setTimeArrivalError("O campo Hora chegada é obrigatório");
        return;
      } else setTimeArrivalError("");

      if (e.target[6].value === "") {
        setCompanyError("O campo Companhia é obrigatório");
        return;
      } else setCompanyError("");

      if (e.target[7].value === "") {
        setSourceError("O campo Origem é obrigatório");
        return;
      } else setSourceError("");

      if (e.target[8].value === "") {
        setDestinyError("O campo Destino é obrigatório");
        return;
      } else setDestinyError("");

      setIsloading(true);

      return await updateFlight({
        event: props.idEvent,
        login: participantSelected,
        boardingdate: e.target[2].value,
        boardingtill: e.target[3].value,
        arrivaldate: e.target[4].value,
        arrivaltime: e.target[5].value,
        company: e.target[6].value,
        origin: e.target[7].value,
        destination: e.target[8].value,
        locator: e.target[9].value,
        eticket: e.target[10].value,
        flightnumber: e.target[11].value,
        terminal: e.target[12].value,
        seat: e.target[13].value,
        boardinggroup: e.target[14].value,
        extrainformation: e.target[15].value,
        jwt: props.state.global_user_data.data.token,
        id: props.editFlight.id,
      });
    },
    {
      onSuccess: (data) => {
        // console.log("ok", data);
        setIsloading(false);
        const { message } = data;

        if (message === "updated") {
          props.data[0].refetch();
          props.data[1].refetch();
          setsuccess_dlg(true);
        }
      },

      onError: (error) => {
        setIsloading(false);
        // console.log("error", error);
      },
    }
  );

  // async function getAllParticipants() {
  //   await getParticipants({
  //     jwt: props.state.global_user_data.data.token,
  //     idEvent: props.idEvent,
  //     offset: 1,
  //     limit: 0,
  //   })
  //     .then((response) => {
  //       setParticipants(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // }

  function funcParticipants() {
    participants &&
      participants?.map((e) => {
        if (e.id === props.editFlight.login.id) {
          setParticipantSelected(e.id);
        }
      });
  }

  useEffect(() => {
    funcParticipants();
  }, [participants]);

  // useEffect(() => {
  //   getAllParticipants();
  // }, []);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            props.data[0].refetch();
            props.data[1].refetch();
            toggle();
            setsuccess_dlg(false);
          }}
        >
          {"Voo editado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar Voo</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm.mutate(e);
          }}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">ID do Voo</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editFlight.id}
                  disabled
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Participante*</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setParticipantSelected(e.target.value);
                  }}
                >
                  <option>Selecione</option>
                  {participants &&
                    participants.map((e, i) => (
                      <option
                        selected={
                          props.editFlight.login.id === e.id ? true : false
                        }
                        key={i}
                        value={e.id}
                      >
                        {e.nome}
                      </option>
                    ))}
                </select>
                <p style={{ color: "red" }}>{participantError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data embarque*</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  defaultValue={props.editFlight.boardingdate}
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{dateBoardingError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora embarque*</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  defaultValue={props.editFlight.boardingtill}
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{timeBoardingError}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data chegada*</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  defaultValue={props.editFlight.arrivaldate}
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{dateArrivalError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora chegada*</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  defaultValue={props.editFlight.arrivaltime}
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{timeArrivalError}</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Companhia*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editFlight.company}
                />
                <p style={{ color: "red" }}>{companyError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Origem*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editFlight.origin}
                />
                <p style={{ color: "red" }}>{sourcerError}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Destino*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editFlight.destination}
                />
                <p style={{ color: "red" }}>{destinyError}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Localizador</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editFlight.locator}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">E-Ticket</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editFlight.eticket}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Número do Voo</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editFlight.flightnumber}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Terminal</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editFlight.terminal}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Assento</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editFlight.seat}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Grupo de embarque</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editFlight.boardinggroup}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Informações extras</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editFlight.extrainformation}
                />
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              className="btn waves-effect waves-light"
              style={{ width: 80, marginRight: 15 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(Editlight);
