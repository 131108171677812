import writeXlsxFile from "write-excel-file";
import { getSurveysByGroup } from "../../../../utils/services/survey";

export async function quizachetransportXlsx({
  jwt,
  eventId,
  limit,
  search,
  totalItems,
  setFinishExport,
}) {
  const formatDate = (date) => {
    const dateFormat = date.split(" ")[0].split("-").reverse().join("/");
    const time = date.split(" ")[1].slice(0, 8);
    return `${dateFormat} - ${time}`;
  };

  const schema = [
    {
      column: "Nome",
      type: String,
      value: (e) => e.Nome,
    },
    {
      column: "Email",
      type: String,
      value: (e) => e.Email,
    },
    {
      column: "Qual meio de transporte irá utilizar?",
      type: String,
      value: (e) => e["Qual meio de transporte irá utilizar?"],
    },
    {
      column: "Marca",
      type: String,
      value: (e) => e["Marca"],
    },
    {
      column: "Modelo",
      type: String,
      value: (e) => e["Modelo"],
    },
    {
      column: "Placa",
      type: String,
      value: (e) => e["Placa"],
    },
    {
      column: "Ponto de Saída",
      type: String,
      value: (e) => e["Ponto de Saída"],
    },
    {
      column: "Descreva o seu meio de transporte abaixo",
      type: String,
      value: (e) => e["Descreva o seu meio de transporte abaixo"],
    },
    {
      column: "Data e hora da resposta",
      type: String,
      value: (e) => formatDate(e.Data),
    },
  ];

  const allRequestsPromises = [];
  const numberOfRequisitions = Math.ceil(totalItems / limit);

  for (let i = 1; i <= numberOfRequisitions; i += 1) {
    const request = await getSurveysByGroup({
      jwt,
      eventId,
      offset: i,
      limit,
      search,
      reportName: "quizachetransport",
    });
    allRequestsPromises.push(request);
    setTimeout(() => {}, 500);

    const progressBar = document.querySelector(".export-progress");
    const percentageBar = document.querySelector(".export-percentage");

    progressBar.style.width = `${(i / numberOfRequisitions) * 100}%`;
    percentageBar.innerHTML = `${((i / numberOfRequisitions) * 100).toFixed(
      0
    )}%`;

    if (i === numberOfRequisitions) {
      setTimeout(() => {
        setFinishExport(true);
      }, 1000);
    }
  }
  const allDataInSingleArray = allRequestsPromises
    .map((e) => e.data.findAllSurveyData.data)
    .flat();

  if (allDataInSingleArray) {
    await writeXlsxFile(allDataInSingleArray, {
      schema,
      fileName: "relatorio-transporte.xlsx",
    });
  }
}
