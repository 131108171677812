import React from 'react'
import SweetAlert from 'react-bootstrap-sweetalert';

export default function ModalExcelRows({ toggle, rows, columnNames }) {
  const invalidRows = rows.map((row) => ({
    row: row.index + 2,
    error: Object.entries(columnNames)
      .filter(([_key, value]) =>  row[value] === undefined).map((e) => e[0])}));

  return (
    <SweetAlert
      title="Corrija os erros abaixo e reenvie a planilha"
      warning
      confirmButtonText="Ok!"
      confirmBtnBsStyle="success"
      onConfirm={() => {
        toggle();
      }}
      style={{ width: '39.1%' }}
    >
      <div style={{ marginTop: 15}}>
        {invalidRows.length !== 0
        ? invalidRows.map((row) => (
          <p key={row.row}>Linha {row.row}: Informe um participante na(s) coluna(s): {row.error.join(', ')}</p>
          ))
        : <p>Utilize o Modelo correto para fazer a importação da planilha</p>
        }
      </div>
    </SweetAlert>
  )
}