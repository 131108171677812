/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";

//redux
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";

//services
// import { getParticipants } from "../../../utils/services/participants";
import { updateTransfer } from "../../../utils/services/transfer";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function NewTransfer(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state de participantes
  const participants = props.participants;

  const [participantSelected, setParticipantSelected] = useState();
  //state dos campos obrigatórios
  const [participantError, setParticipantError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [sourcerError, setSourceError] = useState("");
  const [destinyError, setDestinyError] = useState("");
  const [dateBoardingError, setDateBoardingError] = useState("");
  const [timeBoardingError, setTimeBoardingError] = useState("");
  const [dateArrivalError, setDateArrivalError] = useState("");
  const [timeArrivalError, setTimeArrivalError] = useState("");
  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const submitForm = async (e) => {
    //tratamento de erros
    if (!participantSelected || participantSelected === "Selecione") {
      setParticipantError("O campo Participante é obrigatório");
      return;
    } else setParticipantError("");

    if (e.target[2].value === "") {
      setCompanyError("O campo Empresa é obrigatório");
      return;
    } else setCompanyError("");

    if (e.target[3].value === "") {
      setSourceError("O campo Origem é obrigatório");
      return;
    } else setSourceError("");

    if (e.target[4].value === "") {
      setDestinyError("O campo Destino é obrigatório");
      return;
    } else setDestinyError("");

    if (e.target[5].value === "") {
      setDateBoardingError("O campo Data embarque é obrigatório");
      return;
    } else setDateBoardingError("");

    if (e.target[6].value === "") {
      setTimeBoardingError("O campo Hora embarque é obrigatório");
      return;
    } else setTimeBoardingError("");

    // if (e.target[6].value === "") {
    //   setDateArrivalError("O campo Data chegada é obrigatório");
    //   return;
    // } else setDateArrivalError("");

    // if (e.target[7].value === "") {
    //   setTimeArrivalError("O campo Hora chegada é obrigatório");
    //   return;
    // } else setTimeArrivalError("");
    setIsloading(true);
    await updateTransfer({
      event: props.idEvent,
      login: participantSelected,
      company: e.target[2].value,
      origin: e.target[3].value,
      destination: e.target[4].value,
      boardingdate: e.target[5].value,
      arrivaldate: e.target[7].value !== "" ? e.target[7].value : null,
      boardingtill: e.target[6].value,
      arrivaltime: e.target[8].value,
      meetingpoint: e.target[9].value,
      drivername: e.target[10].value,
      driverphone: e.target[11].value,
      extrainformation: e.target[12].value,
      jwt: props.state.global_user_data.data.token,
      idTransfer: props.editTransfer.id,
    })
      .then((response) => {
        setIsloading(false);
        setsuccess_dlg(true);
        // console.log(response);
      })
      .catch((error) => setIsloading(false));
  };

  // async function getAllParticipants() {
  //   await getParticipants({
  //     jwt: props.state.global_user_data.data.token,
  //     idEvent: props.idEvent,
  //     offset: 1,
  //     limit: 0,
  //     search: '',
  //   })
  //     .then((response) => {
  //       setParticipants(response.data.data);
  //     })
  //     .catch((error) => {
  //       console.log("error", error);
  //     });
  // }

  function funcParticipants() {
    setParticipantSelected(props.editTransfer.login.id);
  }

  useEffect(() => {
    funcParticipants();
  }, [participants]);

  // useEffect(() => {
  //   getAllParticipants();
  // }, []);
  useEffect(() => {}, [participants]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Editado"}
          onConfirm={() => {
            toggle();
            // props.getAllTransfers();
            props.data[0].refetch();
            props.data[1].refetch();
            setsuccess_dlg(false);
          }}
        >
          {"Transfer editado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Editar Transfer</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm(e);
          }}
        >
          <Row style={{ marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">ID do Transfer</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editTransfer.id}
                  disabled
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div>
                <label
                  className="form-label"
                  onClick={() => console.log(props.editTransfer)}
                >
                  Participante*
                </label>
                {/* <Select
                  value={participantSelected}
                  placeholder={"Selecione"}
                  isMulti={false}
                  onChange={(e) => {
                    handleParticipant(e);
                  }}
                  options={[
                    {
                      options: [
                        ...participants?.map((e) => {
                          return { label: e.nome, value: e.id };
                        }),
                      ],
                    },
                  ]}
                  classNamePrefix="select2-selection"
                /> */}
                <select
                  className="form-control"
                  onChange={(e) => {
                    setParticipantSelected(e.target.value);
                  }}
                >
                  <option>Selecione</option>
                  {participants &&
                    participants.map((e, i) => (
                      <option
                        selected={
                          props.editTransfer.login.id === e.id ? true : false
                        }
                        key={i}
                        value={e.id}
                      >
                        {e.nome}
                      </option>
                    ))}
                </select>
                <p style={{ color: "red" }}>{participantError}</p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Empresa*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editTransfer.company}
                />
                <p style={{ color: "red" }}>{companyError}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Origem*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editTransfer.origin}
                />
                <p style={{ color: "red" }}>{sourcerError}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Destino*</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editTransfer.destination}
                />
                <p style={{ color: "red" }}>{destinyError}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data embarque*</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  defaultValue={props.editTransfer.boardingdate}
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{dateBoardingError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora embarque*</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  defaultValue={props.editTransfer.boardingtill}
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{timeBoardingError}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data chegada</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  defaultValue={props.editTransfer.arrivaldate}
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{dateArrivalError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora chegada</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  defaultValue={props.editTransfer.arrivaltime}
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{timeArrivalError}</p>
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Ponto de encontro</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editTransfer.meetingpoint}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Nome do motorista</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editTransfer.drivername}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Telefone do motorista</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editTransfer.driverphone}
                />
              </div>
            </Col>
          </Row>

          <Row style={{ marginTop: 10, marginBottom: 10 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Informações extras</label>
                <input
                  className="form-control"
                  type="text"
                  defaultValue={props.editTransfer.extrainformation}
                />
              </div>
            </Col>
          </Row>

          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              className="btn waves-effect waves-light"
              style={{ width: 80, marginRight: 15 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewTransfer);
