import React, { useRef, useEffect, useState } from "react";
import {
  Input,
  Card,
  CardBody,
  CardTitle,
  Button,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { createTemplate, updateTemplate } from "../../utils/services/rsvp";
import SweetAlert from "react-bootstrap-sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
//redux
import { connect } from "react-redux";
import ButtonSpinner from "../../components/Buttons/ButtonSpinner";

/* import teste1 from "./teste1.json";
import teste2 from "./teste2.json";
import teste3 from "./teste3.json"; */

const TemplateEditor = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const [btnSave, setBtnSave] = useState(false);
  const [btnSaveExit, setBtnSaveExit] = useState(false);
  const [confirmSaveTemplate, setConfirmSaveTemplate] = useState(false);
  const [confirmEditTemplate, setConfirmEditTemplate] = useState(false);
  const [templateName, setTemplateName] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [errorInvalid, setErrorInvalid] = useState(false);
  const [redirect, setRedirect] = useState(false);

  function goBackUrl() {
    if (location?.state?.urlToBack) {
      navigate(location.state.urlToBack, {
        state: {
          ...location.state,
        },
      });
    } else navigate(-1);
  }

  /* const saveDesign = () => {
    emailEditorRef.current.editor.saveDesign((design) => {
      console.log("saveDesign", design);
      alert("Design JSON has been logged in your developer console.");
    });
  };

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      console.log("exportHtml", html);
      alert("Output HTML has been logged in your developer console.");
    });
  };

  const loadTemplate1 = () => {
    emailEditorRef.current.editor.loadDesign(teste1);
  };
  const loadTemplate2 = () => {
    emailEditorRef.current.editor.loadDesign(teste2);
  };
  const loadTemplate3 = () => {
    emailEditorRef.current.editor.loadDesign(teste3);
  };

  const onDesignLoad = (data) => {
    console.log("onDesignLoad", data);
  };

  const onLoad = () => {
    console.log("onLoad");
  };

  const onReady = () => {
    console.log("onReady");
  }; */

  useEffect(() => {
    if (window?.unlayerLoader) {
      window.unlayerLoader(location.state.type_template);
    } else {
      goBackUrl();
    }
    if (location?.state?.editMode) {
      window.unlayer.loadDesign(JSON.parse(location.state.template.htmlJson));
    }

    return () => window.history.replaceState({}, document.title);
  }, []);

  return (
    <div>
      <MetaTags>
        <title>Editor | Inteegra Plus</title>
      </MetaTags>
      {confirmSaveTemplate && !isLoading && (
        <SweetAlert
          info
          showCancel
          confirmBtnText="Salvar"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={`Salvar Template`}
          onConfirm={() => {
            window.unlayer.exportHtml(async function (data) {
              if (!templateName) {
                setErrorInvalid(true);
                return;
              }

              var json = data.design; // design json
              var text = data.html; // final text
              //console.log(json, text);
              setIsloading(true);
              await createTemplate({
                jwt: props.state.global_user_data.data.token,
                payload: {
                  name: templateName,
                  type: location.state.type_template,
                  htmlString: text,
                  htmlJson: JSON.stringify(json),
                  eventId: location.state.private ? location.state.id : null,
                },
              });
              setIsloading(false);
              setConfirmSaveTemplate(false);
              setSuccessMessage(true);
              setErrorInvalid(false);
              //navigate(-1);

              // Do something with the json and text
            });
          }}
          onCancel={() => {
            setConfirmSaveTemplate(false);
          }}
        >
          Para continuar, defina um nome para esse template
          <br />
          <br />
          <br />
          <FormGroup className="position-relative ">
            <Input
              className={
                errorInvalid ? "form-control is-invalid" : "form-control"
              }
              placeholder=""
              type="text"
              required
              onChange={(e) => setTemplateName(e.target.value)}
              value={templateName}
            />
            <FormFeedback style={{ textAlign: "left", paddingTop: 13 }}>
              Preencha o campo nome do template.
            </FormFeedback>
          </FormGroup>
        </SweetAlert>
      )}
      {confirmEditTemplate && !isLoading && (
        <SweetAlert
          info
          showCancel
          confirmBtnText="Aplicar"
          cancelBtnText="Cancelar"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          title={`Editar Template`}
          onConfirm={() => {
            setIsloading(true);
            window.unlayer.exportHtml(async function (data) {
              var json = data.design; // design json
              var text = data.html; // final text
              await updateTemplate({
                jwt: props.state.global_user_data.data.token,
                payload: {
                  id: location.state.template.id,
                  name: location.state.template.name,
                  type: location.state.type_template,
                  htmlString: text,
                  htmlJson: JSON.stringify(json),
                  eventId: null,
                },
              });
              setConfirmEditTemplate(false);
              setIsloading(false);
              setSuccessMessage(true);
              //if (redirect) navigate(-1);

              // Do something with the json and text
            });
          }}
          onCancel={() => {
            setConfirmEditTemplate(false);
          }}
        >
          Aplicar alterações no template?
          <br />
          <br />
          <br />
        </SweetAlert>
      )}
      <SweetAlert
        show={successMessage}
        success
        confirmBtnText="OK"
        confirmBtnBsStyle="info"
        title={`Sucesso`}
        onConfirm={() => {
          setSuccessMessage(false);
          if (redirect || !location?.state.editMode) {
            goBackUrl();
          }
        }}
        onCancel={() => {
          setSuccessMessage(false);
        }}
      >
        Template foi salvo com sucesso.
      </SweetAlert>
      <Card>
        <CardBody>
          <CardTitle
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>Editor de Templates</span>
            <Button
              color="danger"
              className="btn btn-danger waves-effect waves-light save-btn-form"
              style={{ width: 80, marginRight: 10 }}
              onClick={() => {
                goBackUrl();
              }}
            >
              Voltar
            </Button>
          </CardTitle>

          <React.StrictMode>
            <div
              id="editor-container"
              style={{ width: "100%", height: "80vh" }}
            ></div>
            {!location?.state.editMode ? (
              <div
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  display: "flex",
                  marginTop: 20,
                }}
              >
                {/* <Button
                  color="success"
                  className="btn btn-success waves-effect waves-light save-btn-form"
                  style={{ width: 125 }}
                  onClick={() => setConfirmSaveTemplate(true)}
                >
                  Salvar e Sair
                </Button> */}
                <ButtonSpinner
                  isLoading={btnSaveExit && isLoading}
                  title={"Salvar e Sair"}
                  size={125}
                  onClick={() => {
                    setBtnSave(false);
                    setBtnSaveExit(true);
                    setConfirmSaveTemplate(true);
                  }}
                />
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  justifyContent: "flex-end",
                  display: "flex",
                  marginTop: 10,
                }}
              >
                <ButtonSpinner
                  isLoading={btnSave && isLoading}
                  title={"Salvar"}
                  onClick={() => {
                    setBtnSave(true);
                    setConfirmEditTemplate(true);
                  }}
                />
                <div style={{ marginLeft: 10 }}>
                  <ButtonSpinner
                    isLoading={btnSaveExit && isLoading}
                    title={"Salvar e Sair"}
                    size={125}
                    onClick={() => {
                      setBtnSave(false);
                      setBtnSaveExit(true);
                      setRedirect(true);
                      setConfirmEditTemplate(true);
                    }}
                  />
                </div>
              </div>
            )}
          </React.StrictMode>
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { state };
};

const mapDispatchToProps = function (dispatch) {
  return {
    openEvent: (data) => {
      return dispatch({ type: "OPEN_EVENT_MENU", data });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateEditor);
