import { urlBaseContract } from "./config";
import axiosInstance from "../services/config/axiosinstance";

const baseUrl = urlBaseContract;

function createContract(contract, jwt) {
  var config = {
    method: "post",
    url: `${baseUrl}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: contract,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function getContract({ jwt, offset, limit, search, status = true }) {
  var config = {
    method: "get",
    url: `${baseUrl}?offset=${offset}&limit=${limit}&enabled=${status}&search=${search}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function deleteContract({ id, jwt }) {
  var config = {
    method: "delete",
    url: `${baseUrl}/${id}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

function editContract(contract, jwt) {
  var config = {
    method: "put",
    url: `${baseUrl}/${contract.id}`,
    headers: {
      "Content-Type": "application/json",
    },
    data: contract,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}
function enableContract(jwt, contract) {
  var config = {
    method: "put",
    url: `${baseUrl}/${contract}/enable`,
    headers: {
      "Content-Type": "application/json",
    },
    // data: contract,
  };
  return axiosInstance(config)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error.response.data;
    });
}

export {
  createContract,
  enableContract,
  getContract,
  deleteContract,
  editContract,
};
