import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import reportWebVitals from "./reportWebVitals";
import UserRoutes from "./utils/router/AllRoutes";
//redux
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";

import { QueryClient, QueryClientProvider } from "react-query";
import { store, persistor } from "./utils/store/configStore";

//estilos
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/theme.scss";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://a07306d9d85758af36f2989367f6723b@o1192043.ingest.us.sentry.io/4507662865072128",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  // tracePropagationTargets: ["localhost", /^https:\/\/admin-plus\.inteegratec\.com\.br\//,/^https:\/\/admin-plus\.sistemasinteegra\.com\.br\//],
  tracePropagationTargets: [
    /^https:\/\/admin-plus\.sistemasinteegra\.com\.br\//,
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

const queryClient = new QueryClient();

const app = (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <UserRoutes />
      </PersistGate>
    </Provider>
  </QueryClientProvider>
);

createRoot(document.getElementById("root")).render(app);
reportWebVitals();
