import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader, Col, Row, Button } from "reactstrap";

//redux
import { connect } from "react-redux";

import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";

import { useMutation } from "react-query";

//services
import { createFlight } from "../../../utils/services/flight";
import ButtonSpinner from "../../../components/Buttons/ButtonSpinner";

function NewFlight(props) {
  const { isOpen, toggle } = props;
  const [modalBackdrop, setModalBackdrop] = useState(false);
  const [participantSelected, setParticipantSelected] = useState();
  //confirmção de criação
  const [success_dlg, setsuccess_dlg] = useState(false);
  //state dos campos obrigatórios
  const [participantError, setParticipantError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [sourcerError, setSourceError] = useState("");
  const [destinyError, setDestinyError] = useState("");
  const [dateBoardingError, setDateBoardingError] = useState("");
  const [timeBoardingError, setTimeBoardingError] = useState("");
  const [dateArrivalError, setDateArrivalError] = useState("");
  const [timeArrivalError, setTimeArrivalError] = useState("");
  const [isLoading, setIsloading] = useState(false);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  const submitForm = useMutation(
    async (e) => {
      //tratamento de erros
      if (!participantSelected || participantSelected === "Selecione") {
        setParticipantError("O campo Participante é obrigatório");
        return;
      } else setParticipantError("");

      if (e.target[1].value === "") {
        setDateBoardingError("O campo Data embarque é obrigatório");
        return;
      } else setDateBoardingError("");

      if (e.target[2].value === "") {
        setTimeBoardingError("O campo Hora embarque é obrigatório");
        return;
      } else setTimeBoardingError("");

      if (e.target[3].value === "") {
        setDateArrivalError("O campo Data chegada é obrigatório");
        return;
      } else setDateArrivalError("");

      if (e.target[4].value === "") {
        setTimeArrivalError("O campo Hora chegada é obrigatório");
        return;
      } else setTimeArrivalError("");

      if (e.target[5].value === "") {
        setCompanyError("O campo Companhia é obrigatório");
        return;
      } else setCompanyError("");

      if (e.target[6].value === "") {
        setSourceError("O campo Origem é obrigatório");
        return;
      } else setSourceError("");

      if (e.target[7].value === "") {
        setDestinyError("O campo Destino é obrigatório");
        return;
      } else setDestinyError("");

      setIsloading(true);

      return await createFlight({
        event: props.idEvent,
        login: participantSelected,
        boardingdate: e.target[1].value,
        boardingtill: e.target[2].value,
        arrivaldate: e.target[3].value,
        arrivaltime: e.target[4].value,
        company: e.target[5].value,
        origin: e.target[6].value,
        destination: e.target[7].value,
        locator: e.target[8].value,
        eticket: e.target[9].value,
        flightnumber: e.target[10].value,
        terminal: e.target[11].value,
        seat: e.target[12].value,
        boardinggroup: e.target[13].value,
        extrainformation: e.target[14].value,
        jwt: props.state.global_user_data.data.token,
      });
    },
    {
      onSuccess: (data) => {
        // console.log('ok', data);
        setIsloading(false);
        const { message } = data;

        if (message === "created") {
          props.data[0].refetch();
          props.data[1].refetch();
          setsuccess_dlg(true);
        }
      },

      onError: (error) => {
        setIsloading(false);
        // console.log("error", error);
      },
    }
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      autoFocus={true}
      centered={true}
    >
      {success_dlg ? (
        <SweetAlert
          success
          title={"Criado"}
          onConfirm={() => {
            props.data[0].refetch();
            props.data[1].refetch();
            toggle();
            setsuccess_dlg(false);
          }}
        >
          {"Voo criado com sucesso!"}
        </SweetAlert>
      ) : null}
      <ModalHeader toggle={toggle}>Novo Voo</ModalHeader>
      <ModalBody>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            submitForm.mutate(e);
          }}
        >
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Participante*</label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setParticipantSelected(e.target.value);
                  }}
                >
                  <option>Selecione</option>
                  {props.participants &&
                    props.participants.map((e, i) => (
                      <option key={i} value={e.id}>
                        {e.nome}
                      </option>
                    ))}
                </select>
                <p style={{ color: "red" }}>{participantError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data embarque*</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{dateBoardingError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora embarque*</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{timeBoardingError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={6}>
              <div>
                <label className="form-label">Data chegada*</label>
                <input
                  className="form-control"
                  type="date"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{dateArrivalError}</p>
              </div>
            </Col>
            <Col lg={6}>
              <div>
                <label className="form-label">Hora chegada*</label>
                <input
                  className="form-control"
                  type="time"
                  // defaultValue="2019-08-19T13:45:00"
                  id="example-datetime-local-input"
                />
                <p style={{ color: "red" }}>{timeArrivalError}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Companhia*</label>
                <input className="form-control" type="text" />
                <p style={{ color: "red" }}>{companyError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Origem*</label>
                <input className="form-control" type="text" />
                <p style={{ color: "red" }}>{sourcerError}</p>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Destino*</label>
                <input className="form-control" type="text" />
                <p style={{ color: "red" }}>{destinyError}</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div>
                <label className="form-label">Localizador</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">E-Ticket</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Número do Voo</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Terminal</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Assento</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Grupo de embarque</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            <Col lg={12}>
              <div>
                <label className="form-label">Informações extras</label>
                <input className="form-control" type="text" />
              </div>
            </Col>
          </Row>
          <Row
            style={{
              width: "100%",
              margin: "auto",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="danger"
              className="btn waves-effect waves-light"
              style={{ width: 80, marginRight: 15 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner isLoading={isLoading} title={"Salvar"} />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = function (state) {
  return { state: state };
};

export default connect(mapStateToProps)(NewFlight);
