import React, { useState, useEffect } from "react";
import {
  Table,
  Card,
  CardBody,
  Collapse,
  Button,
  CardTitle,
  Row,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import { connect } from "react-redux";
import { useLocation } from "react-router-dom";

import Pagination from "../../../components/Paginationv3";

import useDebounce from "../../../utils/hooks/useDebounce";
import SectionComponent from "../../Apps/Personalization/SectionComponent";

import ModalNewPromo from "./modalNewPromo";
import ModelViewPromo from "./modalViewPromo";

import { listPromos, deletePromo } from "../../../utils/services/payment";
import ModalViewPromo from "./modalViewPromo";
import ModalImportCupom from "./modalImportCupom";

function Promo(props) {
  const location = useLocation();



  useEffect(() => {
    console.log({ location, props });
  }, [location, props]);

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 300);

  const [currentIndex, setCurrentIndex] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [promos, setPromos] = useState([]);
  const [countData, setCountData] = useState(0);

  const [deletePromoId, setDeletePromoId] = useState("");
  const [deletePromoCode, setDeletePromoCode] = useState("");

  const [confirmAlert, setConfirmAlert] = useState(false);
  const [successDlg, setSuccessDlg] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [dynamicDescription, setdynamicDescription] = useState("");

  const [errorAlert, setErrorAlert] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);

  const [modalNewPromo, setModalNewPromo] = useState(false);
  const [modalViewPromo, setModalViewPromo] = useState(false);

  const [modalImportCupom, setModalImportCupom] = useState(false);

  const [promoView, setPromoView] = useState();

  async function handleListPromos() {
    await listPromos({
      jwt: props.state.global_user_data.data.token,
      eventId: location.state.idEvent,
      offset: currentIndex > 0 ? currentIndex : 1,
      limit: itemsPerPage,
      search: debouncedSearch,
    })
      .then((response) => {
        const percentFormatter = new Intl.NumberFormat("pt-BR", {
          style: "decimal",
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });

        const monetaryFormatter = new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        });

        const formatter = {
          M: (value) => monetaryFormatter.format(value),
          P: (value) => `${percentFormatter.format(value)}%`,
        };

        const promos = response.promos.map((promo) => {
          let newDate = new Date(promo.expireAt);
          newDate.setUTCHours(newDate.getUTCHours() + 3);
          return {
            id: promo.id,
            code: promo.code,
            quantity: promo.quantityAvailable,
            value: formatter[promo.valueType](promo.value),
            originalValue: promo.value,
            valueType: promo.valueType,
            expireAt: newDate.toLocaleString("pt-BR", {
              timeZone: "America/Sao_Paulo",
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }),
            products: promo.products.map((product) => product.productId),
            expireAtView: promo.expireAt,
          };
        });

        setPromos(promos);
        setCountData(response.total);
      })
      .catch((error) => {
        setPromos([]);
        setCountData(0);
      });
  }

  async function handleDeletePromo() {
    await deletePromo({
      id: deletePromoId,
      jwt: props.state.global_user_data.data.token,
    })
      .then(async (res) => {
        if (res === 204) {
          handleListPromos();
          setConfirmAlert(false);
          setSuccessDlg(true);
          setDynamicTitle("Deletado");
          setdynamicDescription("Cupom Deletado");
        }
      })
      .catch((error) => {
        setErrorAlert(true);
      });
  }

  async function handleDeleteClick(id, code) {
    setDeletePromoId(id);
    setDeletePromoCode(code);
    setErrorAlert(false);
    setConfirmAlert(true);
  }
  async function handleClickView(promo) {
    setPromoView(promo);
    setModalViewPromo(true);
  }

  useEffect(() => {
    handleListPromos();
  }, [currentIndex, itemsPerPage, debouncedSearch]);

  return (
    <>
      {modalNewPromo && (
        <ModalNewPromo
          isOpen={modalNewPromo}
          toggle={() => setModalNewPromo(!modalNewPromo)}
          idEvent={location.state.idEvent}
          list={handleListPromos}
        />
      )}

      {modalViewPromo && (
        <ModalViewPromo
          isOpen={modalViewPromo}
          toggle={() => setModalViewPromo(!ModalViewPromo)}
          idEvent={location.state.idEvent}
          list={handleListPromos}
          promo={promoView}
        />
      )}

      {modalImportCupom && (
        <ModalImportCupom
          isOpen={modalImportCupom}
          toggle={() => setModalImportCupom(!modalImportCupom)}
          jwt={props.state.global_user_data.data.token}
          eventId={location.state.idEvent}
          list={handleListPromos}
        />
      )}

      {confirmAlert ? (
        <SweetAlert
          title={`Deseja deletar ${deletePromo ? deletePromoCode : ""}?`}
          warning
          showCancel
          confirmBtnText="Sim"
          cancelBtnText="Não"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => handleDeletePromo()}
          onCancel={() => setConfirmAlert(false)}
        >
          Você não poderá reverter essa ação!
        </SweetAlert>
      ) : errorAlert ? (
        <SweetAlert
          title={`Não foi possível excluir o cupom: ${deletePromoCode}`}
          error
          showCancel
          cancelBtnText="Fechar"
          showConfirm={false}
          cancelBtnBsStyle="danger"
          onCancel={() => {
            setErrorAlert(false);
          }}
        ></SweetAlert>
      ) : null}

      {successDlg ? (
        <SweetAlert
          success
          title={dynamicTitle}
          onConfirm={() => {
            setSuccessDlg(false);
          }}
        >
          {dynamicDescription}
        </SweetAlert>
      ) : null}

      <Card>
        <CardBody>
          <CardTitle>Cupons</CardTitle>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              marginBottom: 10,
            }}
          >
            <form
              className="app-search d-none d-lg-block events-input-search"
              onSubmit={(e) => e.preventDefault()}
            >
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pesquisar..."
                  onChange={(e) => setSearch(e.target.value)}
                />
                <span className="bx bx-search-alt"></span>
              </div>
            </form>
            <div className="mobile-btn-section" style={{ width: "100%" }}>
              <SectionComponent
                sectionName="Opções"
                setIsOpen={setIsCollapse}
                isOpen={isCollapse}
              />
              <Collapse isOpen={isCollapse}>
                <Row className="mobile-btns-top">
                  <i
                    onClick={() => setModalNewPromo(true)}
                    className="mdi mdi-plus"
                    style={{
                      color: "green",
                      fontSize: 17,
                      cursor: "pointer",
                    }}
                  />
                </Row>
              </Collapse>
            </div>

            <div className="header-btns-container">
              <Button
                onClick={() => setModalImportCupom(true)}
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
              >
                Importar Cupons
              </Button>

              <Button
                onClick={() => setModalNewPromo(true)}
                color="success"
                className="btn-rounded waves-effect waves-light events-button-add"
              >
                Adicionar Cupom
              </Button>
            </div>
          </div>
          <div className="table-responsive">
            <Table className="table mb-0">
              <thead className="table-light">
                <tr>
                  <th>Código</th>
                  <th>Quantidade</th>
                  <th>Desconto</th>
                  <th>Validade</th>
                  <th
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Ações
                  </th>
                </tr>
              </thead>
              <tbody>
                {promos.map((promo, index) => {
                  return (
                    <tr key={index}>
                      <td>{promo.code}</td>
                      <td>{promo.quantity}</td>
                      <td>{promo.value}</td>
                      <td>{promo.expireAt}</td>
                      <td
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <i
                          className="mdi mdi-eye"
                          style={{
                            color: "blue",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleClickView(promo);
                          }}
                        />
                        <i
                          className="mdi mdi-trash-can"
                          style={{
                            color: "red",
                            fontSize: 17,
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            handleDeleteClick(promo.id, promo.code);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </CardBody>
      </Card>
      <div className="bottom-navigation" style={{ justifyContent: "end" }}>
        <Pagination
          data={promos}
          search={search}
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          maxItemPerPage={itemsPerPage}
          setMaxItemPerPage={setItemsPerPage}
          countData={countData}
          maxPageButtonsToShow={3}
        />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return { state };
};

export default connect(mapStateToProps)(Promo);
