import React, { useEffect } from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
  useLocation,
  Outlet,
  useRouteError,
} from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import { isTokenExpired } from "../services/config/authToken";

// Auth pages
// import Dashboard from "../../pages/Dashboard";
import Events from "../../pages/Events";
import AboutEvent from "../../pages/AboutEvent";
import Users from "../../pages/Users";
import Historic from "../../pages/Historic";
import Contracts from "../../pages/Contracts";
import AppsMobile from "../../pages/Apps";
import HtmlEditor from "../../pages/HtmlEditor";
import Templates from "../../pages/Templates";
import Drive from "../../pages/Drive";
import Dictionary from "../../pages/Dictionary";
import InteegraCreator from "../../pages/InteegraCreator";
import EventType from "../../pages/EventType";

// Default Pages
import Login from "../../pages/LoginPage";

// About Event Screens
import Participants from "../../pages/AboutEvent/Participant/Participants";
import Schedule from "../../pages/AboutEvent/Schedule/Schedule";
import Feed from "../../pages/AboutEvent/Feed/Feed";
import Notification from "../../pages/AboutEvent/Notification/Notification";
import Menu from "../../pages/AboutEvent/Menu/Menu";
import SuperTela from "../../pages/AboutEvent/SuperTela/SuperTela";
import Group from "../../pages/AboutEvent/group/group";
import Voo from "../../pages/AboutEvent/Voo/Voo";
import Transfer from "../../pages/AboutEvent/Transfer/Transfer";
import Hotel from "../../pages/AboutEvent/Hotel/Hotel";
import Ranking from "../../pages/AboutEvent/ranking";
import ConfigRanking from "../../pages/AboutEvent/ranking/configRanking";
import Rsvp from "../../pages/AboutEvent/rsvp/index";
import Email from "../../pages/AboutEvent/templates/email";
import Web from "../../pages/AboutEvent/templates/web";
import DownloadFiles from "../../pages/AboutEvent/DownLoadFiles";
import ReportParticipants from "../../pages/AboutEvent/DownLoadFiles/Reports/participants/ReportParticipants";
import ReportDownloads from "../../pages/AboutEvent/DownLoadFiles/Reports/downloads/ReportDownloads";
import RegistrationFunctions from "../../pages/AboutEvent/RegistrationFunctions";
import ReportFunctions from "../../pages/AboutEvent/RegistrationFunctions/reportFunctions";
import Certificate from "../../pages/AboutEvent/Certificate";
import ReportCertificate from "../../pages/AboutEvent/Certificate/report";
import ReportSurvey from "../../pages/AboutEvent/Survey/report";
import UploadApp from "../../pages/AboutEvent/UploadApp";
import Dashboard from "../../pages/AboutEvent/Dashboard/Dashboard";
import Match from "../../pages/AboutEvent/Match";
import DashTupperware from "../../pages/AboutEvent/DashTupperware/DashTupperware";
import Promo from "../../pages/AboutEvent/Promo/promo";
import Ticket from "../../pages/AboutEvent/Ticket/ticket";
import Split from "../../pages/AboutEvent/Split/split";
import Sorteio from "../../pages/AboutEvent/Sorteio";
//componentes
import AuthLayout from "../../components/Layout";

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const CheckAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const refreshToken = useSelector(
    (state) => state.global_user_data?.data?.refreshToken
  );

  useEffect(() => {
    if (location.pathname === "/logout") {
      localStorage.removeItem("authUser");
      return navigate("/login");
    }

    if (!localStorage.getItem("authUser")) {
      window.location.reload(false);
      localStorage.setItem("authUser", "ok");
      return navigate("/login");
    }
    const isRefreshTokenExpired = isTokenExpired(refreshToken);
    if (isRefreshTokenExpired) {
      localStorage.removeItem("authUser");
      return navigate("/login");
    }
  });
  return <AuthLayout />;
};

function ErrorBoundary() {
  const navigate = useNavigate();

  /* navigate("/login");
  window.location.reload(false); */
  // Uncaught ReferenceError: path is not defined
  return <div>Error!</div>;
}

const NotFound = () => {
  return <div>Página Não encontrada!</div>;
};

const router = createBrowserRouter([
  {
    path: "/login",
    element: (
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      >
        <Login />
      </GoogleReCaptchaProvider>
    ),
    errorElement: <ErrorBoundary />,
  },
  {
    path: "/",
    element: <CheckAuth />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "*",
        element: <NotFound />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/",
        element: <Events />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/events",
        element: <Events />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/event",
        element: <AboutEvent />,
        errorElement: <ErrorBoundary />,
        children: [
          {
            path: "/event/agenda",
            element: <Schedule />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/logistica/voo",
            element: <Voo />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/logistica/transfer",
            element: <Transfer />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/logistica/hotel",
            element: <Hotel />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/participantes",
            element: <Participants />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/dashboard-tupperware",
            element: <DashTupperware />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/certificados",
            element: <Certificate />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/certificados/relatorio",
            element: <ReportCertificate />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/dashboard",
            element: <Dashboard />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/download/documentos",
            element: <DownloadFiles />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/download/relatorio/participantes",
            element: <ReportParticipants />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/download/relatorio/downloads",
            element: <ReportDownloads />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/feed",
            element: <Feed />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/functions",
            element: <RegistrationFunctions />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/functions/relatorio",
            element: <ReportFunctions />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/ranking",
            element: <Ranking />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/ranking/settings",
            element: <ConfigRanking />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/grupos",
            element: <Group />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/menu",
            element: <Menu />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/notifications",
            element: <Notification />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/match/relatorio",
            element: <Match />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/questionario/relatorio",
            element: <ReportSurvey />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/upload/relatorio",
            element: <UploadApp />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/rsvp/formulario",
            element: <Rsvp />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/templates/email",
            element: <Email />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/templates/web",
            element: <Web />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/templates",
            element: <HtmlEditor />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/superTela",
            element: <SuperTela />,
            errorElement: <ErrorBoundary />,
          },
          {
            path: "/event/sorteio",
            element: <Sorteio />,
            errorElement: <ErrorBoundary />,
          },
        ],
      },

      {
        path: "/type-event",
        element: <EventType />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/users",
        element: <Users />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/historic",
        element: <Historic />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/contracts",
        element: <Contracts />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/mobile-apps",
        element: <AppsMobile />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/templates",
        element: <Templates />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/inteegra-creator",
        element: <InteegraCreator />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/inteegra-drive",
        element: <Drive />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/dictionary",
        element: <Dictionary />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/html-editor",
        element: <HtmlEditor />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/event/cupons",
        element: <Promo />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/event/tickets",
        element: <Ticket />,
        errorElement: <ErrorBoundary />,
      },
      {
        path: "/event/splits",
        element: <Split />,
        errorElement: <ErrorBoundary />,
      },
    ],
  },
]);

const UserRoutes = () => (
  <RouterProvider router={router} errorElement={<ErrorBoundary />} />
);

export default UserRoutes;
