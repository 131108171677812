export const excelColumnsPtBr = {
  Hospede1: "host_one",
  Hospede2: "host_two",
  Hospede3: "host_three",
  Hospede4: "host_four",
};

export function allExcelColumns(row, index) {
  const formatedRow = {
    index,
    host_one: row.host_one ? row.host_one : undefined,
    host_two: row.host_two ? row.host_two : undefined,
    host_three: row.host_three ? row.host_three : "Vazio",
    host_four: row.host_four ? row.host_four : "Vazio",
  };

  return formatedRow;
}

export function findRowsWithError(validRows) {
  const rowsWithError = validRows.filter(
    (row) => row.host_one === undefined || row.host_two === undefined
  );

  return rowsWithError;
}