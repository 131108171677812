import { urlBaseGuests } from "./config";
import axiosInstance from "../services/config/axiosinstance";

async function getEmailsInEvent({ jwt, eventId, offset, limit }) {
  const config = {
    method: "get",
    url: `${urlBaseGuests}/events/${eventId}/guests?offset=${offset}&limit=${limit}`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  return axiosInstance(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error.response.data;
    });
}

export { getEmailsInEvent };
