import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  UncontrolledTooltip,
} from "reactstrap";
import { createApp } from "../../utils/services/mobileApps";
import { getFirebaseProjects } from "../../utils/services/firebaseProjects";
import { getMenusDefault } from "./menuDefault";
import ButtonSpinner from "../../components/Buttons/ButtonSpinner";

// Icons
import { FaImages } from "react-icons/fa6";
import { IoIosColorPalette } from "react-icons/io";

import "./styled.css";
import ColorPicker from "../../components/ColorPicker/ColorPicker";

export default function NewApps(props) {
  const { isOpen, toggle, refetch, contractId } = props;
  const [isLoading, setIsloading] = useState(false);
  const { token } = useSelector((state) => state.global_user_data.data);
  const [modalBackdrop, setModalBackdrop] = useState(false);

  //campos fomulário
  const [app, setApp] = useState("");
  const [bundleId, setBundleId] = useState("");
  const [termoDeAcesso, setTermoDeAcesso] = useState("");
  const [telaPrincipal, setTelaPrincipal] = useState("");

  //validações campos formulários
  const [appError, setAppError] = useState(false);
  const [bundleIdError, setBundleIdError] = useState(false);
  const [idFirebaseProjectError, setIdFirebaseProjectError] = useState(false);

  //cadastrado com sucesso
  const [success, setSuccess] = useState(false);

  const [firebaseProjects, setFirebaseProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [firstAcess, setFirstAcess] = useState("não");
  const [primaryColor, setPrimaryColor] = useState("#fff");
  const [secondaryColor, setSecondaryColor] = useState("#fff");
  const [changeColorsLogistics, setChangeColorsLogistics] = useState("não");
  const [changeColorsLogin, setChangeColorsLogin] = useState("não");
  const [colorsLogistic, setColorsLogistic] = useState({
    voo: "#fff",
    transfer: "#fff",
    hotel: "#fff",
    color1: "#fff",
    color2: "#fff",
  });

  const handleInputChange = (name, value) => {
    setColorsLogistic((prevColors) => ({
      ...prevColors,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (changeColorsLogistics === "não") {
      setColorsLogistic((prevColors) => ({
        ...prevColors,
        voo: "#fff",
        transfer: "#fff",
        hotel: "#fff",
      }));
    }
  }, [changeColorsLogistics]);

  useEffect(() => {
    if (changeColorsLogin === "não") {
      setColorsLogistic((prevColors) => ({
        ...prevColors,
        color1: "#fff",
        color2: "#fff",
      }));
    }
  }, [changeColorsLogin]);

  // state options
  const [options, setOptions] = useState([]);
  const [code, setCode] = useState("Selecione");
  const [config, setConfig] = useState([]);

  function toggleBackdrop() {
    setModalBackdrop(!modalBackdrop);
  }

  function resetFields() {
    setApp("");
    setBundleId("");

    setAppError(false);
    setBundleIdError(false);
    setIdFirebaseProjectError(false);
  }

  const getCode = (value) => {
    switch (value) {
      case "Geral":
        return "GERAL";
      case "Agenda":
        return "AGENDA";
      case "Feed":
        return "FEED";
      case "Localização":
        return "LOCALIZATION";
      case "Logística":
        return "LOGISTICA";
      case "Ranking":
        return "RANKING";
      case "Convidados":
        return "CONVIDADOS";
      case "Networking":
        return "NETWORKING";
      case "Notes":
        return "NOTES";

      default:
        return "";
    }
  };

  async function submitForm() {
    if (app === "") {
      return setAppError(true);
    } else setAppError(false);

    if (!selectedProject || selectedProject === "Selecione") {
      return setIdFirebaseProjectError(true);
    } else setIdFirebaseProjectError(false);

    if (bundleId === "") {
      return setBundleIdError(true);
    } else setBundleIdError(false);
    setIsloading(true);
    return createApp({
      jwt: token,
      name: app,
      bundleId,
      idFirebaseProject: selectedProject,
      contractId,
      termoDeAcesso: termoDeAcesso,
      telaPrincipal:
        telaPrincipal === "Selecione" ? "" : getCode(telaPrincipal),
    })
      .then((data) => {
        setIsloading(false);
        setSuccess(true);
        resetFields();
      })
      .then(() => refetch())
      .catch((error) => {
        setIsloading(false);

        // console.log({ error });
      });
  }

  function getMenus() {
    const newOptions = getMenusDefault();
    setOptions([...newOptions]);
  }
  useEffect(() => {
    if (isOpen) {
      getMenus();
      getFBProjects();
      return;
    }
    setSelectedProject(null);
    setSelectedProject(null);
  }, [isOpen]);

  function getFBProjects() {
    getFirebaseProjects({ jwt: token }).then((res) => {
      setFirebaseProjects(res.findAll.data);
    });
  }

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={() => {
        toggleBackdrop();
      }}
      onClosed={() => {
        resetFields();
      }}
      autoFocus={true}
      centered={true}
    >
      {success && (
        <SweetAlert
          success
          title="Cadastro"
          onConfirm={() => {
            setSuccess(false);
            toggle();
          }}
        >
          App cadastrado com sucesso!
        </SweetAlert>
      )}
      <ModalHeader toggle={toggle}>Novo App</ModalHeader>
      <ModalBody>
        <form>
          <div>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label
                  className="form-label text-sm"
                  style={{ display: "flex" }}
                >
                  Nome do Aplicativo*
                  <div
                    id="nameApp"
                    style={{ height: "16px", paddingRight: "7px" }}
                  >
                    <div className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target="nameApp"
                    >
                      Nome que será exibido nas lojas e no dispositivo do
                      usuário quando o APP for baixado
                    </UncontrolledTooltip>
                  </div>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setApp(e.target.value)}
                />
                {appError && (
                  <p style={{ color: "red" }}>
                    O campo Nome do Aplicativo é obrigatório.
                  </p>
                )}
              </Col>
            </Row>
          </div>
          <div>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label
                  className="form-label text-sm"
                  style={{ display: "flex" }}
                >
                  ID do Projeto (Firebase)*
                  <div
                    id="projectId"
                    style={{ height: "16px", paddingRight: "7px" }}
                  >
                    <div className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target="projectId"
                    >
                      Projeto do Firebase que será responsável por enviar os
                      pushs e guardar as informações do chat individual,
                      anotações, super tela
                    </UncontrolledTooltip>
                  </div>
                </label>
                <select
                  className="form-control"
                  onChange={(e) => {
                    setSelectedProject(e.target.value);
                  }}
                >
                  <option>Selecione</option>
                  {firebaseProjects &&
                    firebaseProjects?.map((e, i) => (
                      <option key={i}>{e.name}</option>
                    ))}
                </select>
                {idFirebaseProjectError && (
                  <p style={{ color: "red" }}>
                    O campo ID do Projeto (Firebase) é obrigatório.
                  </p>
                )}
              </Col>
            </Row>
          </div>

          <div>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label
                  className="form-label text-sm"
                  style={{ display: "flex" }}
                >
                  Bundle ID*
                  <div
                    id="bundleId"
                    style={{ height: "16px", paddingRight: "7px" }}
                  >
                    <div className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target="bundleId"
                    >
                      Código identificador do APP nas lojas
                    </UncontrolledTooltip>
                  </div>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setBundleId(e.target.value)}
                />
                {bundleIdError && (
                  <p style={{ color: "red" }}>
                    O campo Bundle ID é obrigatório.
                  </p>
                )}
              </Col>
            </Row>
          </div>

          <div>
            <Row style={{ marginTop: 10 }}>
              <Col>
                <label
                  className="form-label text-sm"
                  style={{ display: "flex" }}
                >
                  Termo de Acesso
                  <div
                    id="term"
                    style={{ height: "16px", paddingRight: "7px" }}
                  >
                    <div className="show-tips">
                      <span>?</span>
                    </div>
                    <UncontrolledTooltip
                      style={{ backgroundColor: "#6f42c1" }}
                      placement="right"
                      target="term"
                    >
                      Termo de acesso que será carregado ao clicar no link da
                      tela de login, caso deixe o campo em branco o aplicativo
                      exibirá a politica de privacidade da Inteegra
                    </UncontrolledTooltip>
                  </div>
                </label>
                <input
                  className="form-control"
                  type="text"
                  onChange={(e) => setTermoDeAcesso(e.target.value)}
                />
              </Col>
            </Row>
          </div>

          <Row style={{ marginTop: 10, marginBottom: 20 }}>
            <Col lg={12}>
              <label className="form-label text-sm" style={{ display: "flex" }}>
                Tela Principal
                <div
                  id="mainScreen"
                  style={{ height: "16px", paddingRight: "7px" }}
                >
                  <div className="show-tips">
                    <span>?</span>
                  </div>
                  <UncontrolledTooltip
                    style={{ backgroundColor: "#6f42c1" }}
                    placement="right"
                    target="mainScreen"
                  >
                    Tela que será exibida ao abrir o aplicativo quando o
                    participante tiver apenas 1 evento
                  </UncontrolledTooltip>
                </div>
              </label>
              <select
                className="form-control"
                onChange={({ target }) => setTelaPrincipal(target.value)}
              >
                {/* <option>Selecione</option> */}
                {/* <option>Geral</option> */}
                {options &&
                  options
                    .filter(
                      (e) =>
                        !config.find(
                          (a) => a.code === e.option || a.code === e.code
                        )
                    )
                    .map((e, i) => (
                      <option key={i}>{e?.describe || e.option}</option>
                    ))}
              </select>
            </Col>

            {/* <Col lg={6}>
              <label className="form-label text-sm" style={{ display: "flex" }}>
                Possui primeiro acesso?
                <div
                  id="firstAcess"
                  style={{ height: "16px", paddingRight: "7px" }}
                >
                  <div className="show-tips">
                    <span>?</span>
                  </div>
                  <UncontrolledTooltip
                    style={{ backgroundColor: "#6f42c1" }}
                    placement="right"
                    target="firstAcess"
                  >
                    Caso selecione "Não" o botão Primeiro acesso não será
                    exibido no aplicativo
                  </UncontrolledTooltip>
                </div>
              </label>
              <select
                value={firstAcess}
                className="form-control"
                onChange={({ target }) => setFirstAcess(target.value)}
              >
                <option value={"sim"}>Sim</option>
                <option value={"não"}>Não</option>
              </select>
            </Col> */}
          </Row>

          {/* <UncontrolledAccordion>
            <AccordionItem>
              <AccordionHeader targetId={1}>
                <label
                  style={{
                    fontSize: 18,
                    display: "flex",
                    justifyContent: "center",
                    gap: 4,
                  }}
                >
                  <FaImages size={20} />
                  Imagens
                </label>
              </AccordionHeader>
              <AccordionBody accordionId={1}>
                <Row style={{ marginTop: 10, marginBottom: 20 }}>
                  <Col md={6}>
                    <label
                      className="form-label text-sm"
                      style={{ display: "flex" }}
                    >
                      Ícone
                      <div
                        id="icon"
                        style={{ height: "16px", paddingRight: "7px" }}
                      >
                        <div className="show-tips">
                          <span>?</span>
                        </div>
                        <UncontrolledTooltip
                          style={{ backgroundColor: "#6f42c1" }}
                          placement="right"
                          target="icon"
                        >
                          1536x1536px
                        </UncontrolledTooltip>
                      </div>
                    </label>
                    <input className="form-control" type="file" />
                  </Col>

                  <Col md={6}>
                    <label
                      className="form-label text-sm"
                      style={{ display: "flex" }}
                    >
                      Splash
                      <div
                        id="splash"
                        style={{ height: "16px", paddingRight: "7px" }}
                      >
                        <div className="show-tips">
                          <span>?</span>
                        </div>
                        <UncontrolledTooltip
                          style={{ backgroundColor: "#6f42c1" }}
                          placement="right"
                          target="splash"
                        >
                          4096x4096px
                        </UncontrolledTooltip>
                      </div>
                    </label>
                    <input className="form-control" type="file" />
                  </Col>
                </Row>

                <Row style={{ marginTop: 10, marginBottom: 20 }}>
                  <Col md={6}>
                    <label
                      className="form-label text-sm"
                      style={{ display: "flex" }}
                    >
                      Logo tela de login
                      <div
                        id="loginScreen"
                        style={{ height: "16px", paddingRight: "7px" }}
                      >
                        <div className="show-tips">
                          <span>?</span>
                        </div>
                        <UncontrolledTooltip
                          style={{ backgroundColor: "#6f42c1" }}
                          placement="right"
                          target="loginScreen"
                        >
                          300x200px
                        </UncontrolledTooltip>
                      </div>
                    </label>
                    <input className="form-control" type="file" />
                  </Col>

                  <Col md={6}>
                    <label
                      className="form-label text-sm"
                      style={{ display: "flex" }}
                    >
                      Logo lista de eventos
                      <div
                        id="logoEvent"
                        style={{ height: "16px", paddingRight: "7px" }}
                      >
                        <div className="show-tips">
                          <span>?</span>
                        </div>
                        <UncontrolledTooltip
                          style={{ backgroundColor: "#6f42c1" }}
                          placement="right"
                          target="logoEvent"
                        >
                          300x200px
                        </UncontrolledTooltip>
                      </div>
                    </label>
                    <input className="form-control" type="file" />
                  </Col>
                </Row>
              </AccordionBody>
            </AccordionItem>

            <AccordionItem>
              <AccordionHeader targetId={2}>
                <label
                  style={{
                    fontSize: 18,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 4,
                  }}
                >
                  <IoIosColorPalette size={20} />
                  Cores
                </label>
              </AccordionHeader>
              <AccordionBody accordionId={2}>
                <Row className="gx-2" style={{ marginBottom: "20px" }}>
                  <Col xs="auto">
                    <ColorPicker
                      classOfContainer="primary-card-pickr"
                      setStateFunc={setPrimaryColor}
                      labelText="Cor Primária"
                      tip="Cor principal do aplicativo"
                      idDiv="corPrincipalCards"
                    />
                  </Col>
                  <Col xs="auto">
                    <ColorPicker
                      classOfContainer="secondary-card-pickr"
                      setStateFunc={setSecondaryColor}
                      labelText="Cor Secundária"
                      tip="Cor dos detalhes do aplicativo (Exemplos: Toogles, alguns botões)"
                      idDiv="corSecundariaCards"
                    />
                  </Col>
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                  <Col md={12}>
                    <label
                      className="form-label text-sm"
                      style={{ display: "flex" }}
                    >
                      Alterar cor dos cards da Logística?
                    </label>
                    <select
                      value={changeColorsLogistics}
                      className="form-control"
                      onChange={({ target }) =>
                        setChangeColorsLogistics(target.value)
                      }
                    >
                      <option value={"sim"}>Sim</option>
                      <option value={"não"}>Não</option>
                    </select>
                  </Col>
                </Row>

                {changeColorsLogistics === "sim" && (
                  <Row className="gx-1" style={{ marginBottom: "20px" }}>
                    <Col xs="auto">
                      <ColorPicker
                        classOfContainer="tertiary-card-pickr"
                        setStateFunc={(color) =>
                          handleInputChange("voo", color)
                        }
                        labelText="Voo"
                        tip="Cor do card do voo da tela Logística"
                        idDiv="corPrincipalCards"
                      />
                    </Col>

                    <Col xs="auto">
                      <ColorPicker
                        classOfContainer="quaternary-card-pickr"
                        setStateFunc={(color) =>
                          handleInputChange("transfer", color)
                        }
                        labelText="Transfer"
                        tip="Cor do card do transfer da tela Logística"
                        idDiv="corTertiaryCards"
                      />
                    </Col>

                    <Col xs="auto">
                      <ColorPicker
                        classOfContainer="quintenary-card-pickr"
                        setStateFunc={(color) =>
                          handleInputChange("hotel", color)
                        }
                        labelText="Hotel"
                        tip="Cor do card do hotel da tela Logística"
                        idDiv="corSecundariaCards"
                      />
                    </Col>
                  </Row>
                )}

                <Row style={{ marginBottom: "20px" }}>
                  <Col md={12}>
                    <label
                      className="form-label text-sm"
                      style={{ display: "flex" }}
                    >
                      Alterar cores do Login?
                    </label>
                    <select
                      value={changeColorsLogin}
                      className="form-control"
                      onChange={({ target }) =>
                        setChangeColorsLogin(target.value)
                      }
                    >
                      <option value={"sim"}>Sim</option>
                      <option value={"não"}>Não</option>
                    </select>
                  </Col>
                </Row>

                {changeColorsLogin === "sim" && (
                  <Row className="gx-1" style={{ marginBottom: "20px" }}>
                    <Col xs="auto">
                      <ColorPicker
                        classOfContainer="septenary-card-pickr"
                        setStateFunc={(color) =>
                          handleInputChange("color1", color)
                        }
                        labelText="Cor 1 (Parte de cima)"
                        tip="Cor que será exibida na parte de cima da tela de login"
                        idDiv="corPrincipalCards"
                      />
                    </Col>

                    <Col xs="auto">
                      <ColorPicker
                        classOfContainer="octonary-card-pickr"
                        setStateFunc={(color) =>
                          handleInputChange("color2", color)
                        }
                        labelText="Cor 2 (Parte de baixo)"
                        tip="Cor que será exibida na parte de baixo da tela de login"
                        idDiv="corSecundariaCards"
                      />
                    </Col>
                  </Row>
                )}
              </AccordionBody>
            </AccordionItem>
          </UncontrolledAccordion> */}

          <Row
            style={{
              width: "100%",
              marginTop: 15,
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              color="success"
              className="btn btn-danger waves-effect waves-light"
              style={{ width: 80, marginRight: 5 }}
              onClick={() => toggle()}
            >
              Fechar
            </Button>
            <ButtonSpinner
              isLoading={isLoading}
              title={"Salvar"}
              onClick={submitForm}
            />
          </Row>
        </form>
      </ModalBody>
    </Modal>
  );
}
